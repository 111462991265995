<template>
  <div class="chart-card">
    <div class="chart-card__header">
      <h2>{{ title }}</h2>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ $t(`message.${selected_item}`) }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in dateItems" @click.native="selected_item = item" :key="item">{{ $t(`message.${item}`) }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="px-3">
      <div class="tableFixHead">
        <table class="table table-hover" v-loading="false">
          <thead>
            <th>
              Врач
            </th>

            <th>
              Консультации
            </th>
            <th>
              Стационар
            </th>
            <th>
              Дневной стационар
            </th>
            <th>
              Сумма
            </th>
          </thead>
          <tbody>
          <tr v-for="(data, doctorId) in reports[selected_item]" :key="doctorId">
            <td class="d-flex align-items-center">
              <el-avatar size="medium" :src="`/img/doctor_icons/2.png`"></el-avatar>
              <p class="m-0 pl-3">{{ data.fullname }}</p>
            </td>
            <td>{{ data.statistic.consultations_count }}</td>
            <td>{{ data.statistic.hospital_count }}</td>
            <td>{{ data.statistic.day_hospital_count }}</td>
            <td>
              <p class="m-0">{{ totalIncome(data) }} сум</p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: ['title', 'reports'],
  data() {
    return {
      show: false,
      selected_item: "daily",
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
      ],
    };
  },
  computed: {
    ...mapGetters({
      mode: 'theme/MODE'
    }),
    totalIncome() {
      return function(data) {
        return data.statistic.consultations_income + data.statistic.hospital_income + data.statistic.day_hospital_income;
      };
    }
  },
  watch: {
    data: function (val) {
      this.setData(val);
    },
    methods: {
      setData(val) {
        this.show = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
body.dark-mode th {
  background: #3e4166
}
.tableFixHead {
  overflow-y: auto;
  height: 300px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  background: #eee;
}

th,
td,
table {
  font-size: 14px;
  border: none !important;
  color: #444;

}

thead {
  border-bottom: 1px #44444417 solid;
}

td {
  p {
    font-weight: 600;
  }
}
</style>