import Layout from '@/layouts'

const doctorCabinet = {
  path: '/doctor-cabinet',
  component: Layout,
  name: 'doctorCabinet',
  redirect: '/doctor-cabinet/patients',
  children: [
    {
      path: '',
      name: 'CabinetLayout',
      component: () => import('@/views/doctorCabinet/layout'),
      children: [
        {
          path: '/doctor-cabinet/patients',
          component: () => import('@/views/doctorCabinet/patients'),
          name: 'doctor-cabinet.patients',
          meta: { title: 'Мой Кабинет' }
        },
        {
          path: '/doctor-cabinet/informal-patients',
          component: () => import('@/views/doctorCabinet/informal-patients'),
          name: 'doctor-cabinet.informal-patients',
          meta: { title: 'Мой Кабинет' }
        },
        {
          path: '/doctor-cabinet/AllPatients',
          component: () => import('@/views/doctorCabinet/AllPatients'),
          name: 'doctor-cabinet.AllPatients',
          meta: { title: 'Пациенты' }
        },

      ]
    }, {
      path: '/doctor-cabinet/patientMedicine',
      component: () => import('@/views/patientMedicine/index'),
      name: 'doctor-cabinet.patientMedicine',
      meta: { title: 'Мой Кабинет' }
    },
    {
      path: '/doctor-cabinet/patientMedicineShow',
      component: () => import('@/views/patientMedicineShow/index'),
      name: 'doctor-cabinet.patientMedicineShow',
      meta: { title: 'Мой Кабинет' }
    },
    {
      path: '/doctor-cabinet/conclusions/:id',
      component: () => import('@/views/doctorCabinet/components/docCabPatientHistory'),
      name: 'patientConclusions',
      meta: { title: 'Пациенты' }
    },
    {
      path: '/laboratory-cabinet/conclusions/:id',
      component: () => import('@/views/laboratoryCabinet/components/docCabPatientHistory'),
      name: 'patientLaboratoryConclusions',
      meta: { title: 'Пациенты' }
    },
    // {
    //   path: '/doctor-cabinet/components/addDocument',
    //   component: () => import('@/views/doctorCabinet/components/addDocument'),
    //   name: 'addDocument',
    //   meta: { title: 'добавить документ'}
    // },
  ]
}

export default doctorCabinet