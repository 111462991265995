<template>
  <el-select
    :value="selected"
    @input="dispatch"
    @change="emitParentEvent"
    :placeholder="plc || $t('message.user')"
    filterable
    clearable
  >
    <el-option
      v-for="item in users"
      :key="item.id"
      :label="
        item.name +
        ' ' +
        item.surname +
        ' ' +
        (item.patronymic ? item.patronymic : '')
      "
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["user_id", "specialty_id", "doctor_id"],
  data() {
    return {
      user: [],
    };
  },
  mounted() {
    if (this.users && this.users.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    user_id: {
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
    specialty_id: {
      handler: function (val, oldVal) {
        this.getInventory({ specialty_id: this.specialty_id });
      },
      deep: true,
      immediate: true,
    },
    doctor_id: {
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      users: "users/doctor_list",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "users/doctor_list",
    }),

    emitParentEvent() {
      this.users.forEach((val) => {
        if (val.id == this.selected) {
          this.user.push(val);
        }
      });
      this.$emit("parent-event", this.selected);
    },
  },
};
</script>
