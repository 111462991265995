
export const mutations = {
    SET_DASHBOARDINFO: (state, data) => {
        state.schedulePatients = data.patients
        state.stasionaryPatients = data.stasionaryPatients
        state.paymentsSum = data.payments
        state.items = data.items
        // state.payment_chart = data.payment_chart
        // state.cost_chart = data.cost_chart
        state.expences = data.expences
        state.age = data.age
        state.reportClinic = data.clinicReports
        state.reportDoctor = data.doctorReports
        state.contractsCount = data.contracts
        state.formTemplates = data.forms
        state.patientScheduleCount = data.patientSchedule
        state.stasionaryPayments = data.stasionaryPayments
        state.warehouseCount = data.warehouseCount
        state.number_of_orders_today = data.number_of_orders_today
        state.number_of_orders_month = data.number_of_orders_month
        state.doctor = data.doctor
        state.partner_doctor = data.partner_doctor
        state.top_services = data.top_services
        state.gender = data.gender
        state.order_chart = data.order_chart
    },
};