import CreatePatient from '@/views/patient/components/crm-create';
import UpdatePatient from '@/views/patient/components/crm-full-update';
import AddPartnerDoctor from '@/views/scheduler/add-partner-doctor'
import AddPartnerClinic from '@/views/scheduler/add-partner-clinic'
import CreatePatientService from '@/views/scheduler/create-patient-service';
import CreateDepartment from '@/views/scheduler/add-department';
import { i18n } from "@/utils/i18n";
import {
    mapGetters,
    mapActions,
    createLogger
} from "vuex";
import _ from 'lodash';

export default {
    props: ['drawer', 'open'],
    components: {
        CreatePatient,
        UpdatePatient,
        AddPartnerDoctor,
        AddPartnerClinic,
        CreatePatientService,
        CreateDepartment
    },
    data() {
        return {
            activeTab: 'first',
            waitingPrint: false,
            waiting: false,
            search: '',
            yes_user: false,
            user: null,
            form: {},
            query: {
                relation: true
            },
            patientServiceId: '',
            loadingData: false,
            selectedPatient: null,
            drawerPartner: false,
            drawerPartnerDoctor: false,
            drawerPartnerClinic: false,
            drawerCreatePatient: false,
            drawerDepartment: false,
            drawerUpdatePatient: false,
            drawerPatientService: false,
            latestServices: [],
            discountPatients: [],
            patientDiscount: false,
            items: []
        }
    },
    computed: {
        ...mapGetters({
            list: 'services/selected_list',
            selected_partner_doctor: 'partnerDoctor/selected_partner_doctor',
            partnerDoctors: 'partnerDoctor/list',
            partnerClinics: 'partnerClinic/inventory_all',
            services: 'services/relation_list',
            servicesSelected: 'services/selected_relation_list',
            patients: 'patients/search_list',
            modelPatient: 'patients/model',
            modelService: 'services/model',
            // rooms: 'rooms/list',
            conditions: 'conditions/list',
            model: 'orders/model',
            express: "expresses/list",
            departments: "department/list",
            money: 'money',
            lastId: "patients/lastId",
            materials: "materials/list",
        }),
        totalAmount() {
            if (_.size(this.items)) {
                if (this.patientDiscount) {
                    return this.items.reduce((a, b) => a + (b.count * this.expressPrice(b.is_express) * (b.price - ((b.price * this.discountPatients[0].discount) / 100)) || 0), 0)
                } else {
                    let self = this;
                    return this.items.reduce(function (a, b) {
                        if ((b.discount_price !== 0)) {
                            return a + (b.count * self.expressPrice(b.is_express) * (b.price - ((b.price * b.discount_price) / 100)) || 0)
                        } else {
                            return a + ((b.count * self.expressPrice(b.is_express) * b.price - b.price_of_discount) || 0)
                        }
                    }, 0);
                }
            }
        },
        totalDiscount() {
            if (_.size(this.items)) {
                if (this.patientDiscount) {
                    return this.items.reduce((a, b) => a + (b.price * this.discountPatients[0].discount * b.count / 100 || 0), 0)
                } else {
                    return this.items.reduce(function (a, b) {
                        if (b.discount_price !== 0) {
                            return a + (b.price * b.discount_price * b.count / 100 || 0)
                        } else {
                            return a + (b.price_of_discount || 0)
                        }
                    }, 0);
                }
            }
        },
    },
    mounted() {
        this.loadingData = true;
        var order_query = {
            column: 'name',
            order: 'asc'
        }
        if (this.services && this.services.length === 0) this.servicesRealtionList(this.query)
        if (this.servicesSelected && this.servicesSelected.length === 0) this.servicesSelectedRealtionList({
            selected_relation: true,
        }).then((res) => {
            this.loadingData = false;
        }).catch((err) => {
            this.loadingData = false;
        })
        if (this.materials && this.materials.length === 0) this.loadMaterial(order_query);
        if (this.departments && this.departments.length === 0) this.loadDepartments(order_query);
        if (this.partnerDoctors && this.partnerDoctors.length === 0) this.partnerDoctorsList(order_query)
        if (this.partnerClinics && this.partnerClinics.length === 0) this.partnerClinicsList(order_query)
        // if (this.rooms && this.rooms.length === 0) this.loadRooms()
        if (this.conditions && this.conditions.length === 0) this.loadConditions()
        this.fetchPatients = _.debounce(this.loadFilteredPatients, 500)
    },
    watch: {
    },
    methods: {
        ...mapActions({
            updateSelectedListItem: 'services/updateSelectedItem',
            loadDepartments: "department/index",
            partnerDoctorsList: 'partnerDoctor/index',
            loadMaterial: "materials/index",
            partnerClinicsList: 'partnerClinic/inventoryAll',
            servicesRealtionList: 'services/relationList',
            servicesSelectedRealtionList: 'services/selectedRelationList',
            updatePatients: 'patients/search',
            // loadRooms: "rooms/index",
            loadConditions: "conditions/index",
            saveList: 'services/setlist',
            emptyList: 'services/emptyList',
            emptySearch: 'patients/emptySearch',
            lastPatientId: 'patients/lastIdPatent',
            editModel: "patients/show",
            printAction: 'orders/print',
            removeSelectedList: 'services/removeSelectedList',
            loadPatientLastOrderServices: 'patients/loadPatientLastOrderServices',
        }),
        opened() {
            // after Opened function 
            var order_query = {
                column: 'name',
                order: 'asc'
            }
            this.loadMaterial(order_query);
            this.partnerDoctorsList(order_query)
            this.partnerClinicsList(order_query)
            // this.emptyList();
        },
        ifValidationError(err) {
            if (err.code == 422) {
                let errors = ''
                for (var k in err.error[0]) {
                    if (err.error[0].hasOwnProperty(k)) {
                        errors = errors + err.error[0][k][0] + '\n';
                    }
                }
                this.$notify({
                    title: 'Ошибка ',
                    type: "error",
                    position: "bottom right",
                    message: errors
                });
            } else {
                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            }
        },
        expressPrice(is_express) {
            if (is_express) {
                return 1 + (this.express.length ? this.express[0].percent : 50) / 100;
            } else {
                return 1;
            }
        },
        noUser(yes = false) {
            this.yes_user = yes;
        },
        searchPatients() {
            this.fetchPatients();
        },
        loadFilteredPatients() {
            const query = {
                search: this.search
            };
            if (!this.loadingData) {
                this.loadingData = true;
                this.updatePatients(query).then(res => {
                    this.noUser();
                    this.user = null;
                    this.latestServices = [];
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        parent() {
            return this.$parent.$parent
        },
        openUpdatePatient(model) {
            this.selectedPatient = model;
            this.drawerUpdatePatient = true;
        },
        itemsChanged(val) {
            this.items = JSON.parse(JSON.stringify(val));
        },
        editRow(row, event, key) {
            let data = {
                id: row.id,
                value: event,
                key: key
            }
            this.updateSelectedListItem(data)
                .then(res => {
                    this.items = JSON.parse(JSON.stringify(this.list));
                }).catch(err => { })
        },
        deleteRow(scope) {
            this.$confirm(
                i18n.t("message.delete_notification_confirm"),
                i18n.t("message.warning"),
                {
                    confirmButtonText: i18n.t("message.yes"),
                    cancelButtonText: i18n.t("message.cancel"),
                    type: "warning",
                }
            )
                .then(() => {
                    this.removeSelectedList(scope.id)
                        .then(res => {
                            this.items = JSON.parse(JSON.stringify(this.list));
                            this.$message({
                                type: 'success',
                                message: i18n.t("message.delete_success"),
                            });
                        }).catch(err => {

                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: i18n.t("message.delete_calcel"),
                    });
                });
        },
        close() {
            this.empty();
            this.parent().closeChildDrawer(this.drawer);
        },
        async closeDrawerAddPartnerDoctor(obj) {
            if (obj.reload) {
                await this.partnerDoctorsList()
                this.form.partner_doctor_id = this.selected_partner_doctor.id
            }
            if (obj.drawer) {
                this[obj.drawer] = false
            }
        },
        // setPatient(patient) {
        //     this.user = JSON.parse(JSON.stringify(patient));
        //     this.noUser(true);
        //     console.log(555);
        // },
        async closeDrawerPatient(obj) {
            if (obj.id) {
                await this.editModel(obj.id)
            }
            if (obj.reload) {
                await this.edit(this.modelPatient);
            }
            if (obj.drawer) {
                this[obj.drawer] = false
            }
        },
        closeDrawerPatientService(obj) {
            if (obj.drawer) {
                this[obj.drawer] = false
            }
        },
        closeDrawerUpdatePatient(obj) {
            if (obj.reload) {
                this.edit(obj.patient)
            }
            if (obj.drawer) {
                this[obj.drawer] = false;
            }
        },
        edit(model) {
            this.user = JSON.parse(JSON.stringify(model));
            this.noUser(true)

            this.patientDiscount = false;
            this.discountPatients = [];
            this.latestServices = [];
            this.loadPatientLastOrderServices(model.id)
                .then(res => {
                    this.latestServices = res.data;
                })
                .catch(err => {
                    console.log(err);
                });
            this.discountPatient(model.id)
                .then(res => {
                    this.discountPatients = res.data;
                    if (res.data.length && res.data[0].status) this.patientDiscount = true
                }).catch(err => { });

        },
        addService() {
            let vm = this;
            let service = this.servicesSelected.find(function (item) {
                return item.id == vm.patientServiceId;
            })
            if (service) {
                this.saveList([JSON.parse(JSON.stringify(service))])
                    .then(res => {
                        this.items = JSON.parse(JSON.stringify(this.list));
                        this.patientServiceId = "";
                    }).catch(err => { });
            }
        },
        changeWaiting(def = false) {
            this.waiting = def;
        },
        getChildrens: function (children) {
            if (children) return children.filter((item) => {
                return item.is_service == true
            });
            return [];
        },
        async addPatient() {
            await this.$store.dispatch('patients/empty');
            this.lastPatientId()
                .then(res => {
                    this.drawerCreatePatient = true;
                }).catch(err => {
                    this.drawerCreatePatient = true;
                });
        },
        print(id) {
            this.waitingPrint = true;
            let vm = this;
            var jqxhr = $.get(process.env.VUE_APP_URL + `orders/print?id=${id}`, function () { })
                .done(function (res) {
                    vm.waitingPrint = false;
                    const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                    WinPrint.document.write(res);
                    WinPrint.document.close();
                    WinPrint.focus();
                    setTimeout(() => {
                        WinPrint.print();
                        WinPrint.close();
                    }, 1000);
                })
                .fail(function () {
                    alert(i18n.t("message.cannot_print"));
                    vm.waitingPrint = false;
                })
        },
        printMini(id) {
            this.waitingPrint = true;
            let vm = this;
            var jqxhr = $.get(process.env.VUE_APP_URL + `orders/print-mini-order?id=${id}`, function () { })
                .done(function (res) {
                    vm.waitingPrint = false;
                    const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                    WinPrint.document.write(res);
                    WinPrint.document.close();
                    WinPrint.focus();
                    setTimeout(() => {
                        WinPrint.print();
                        WinPrint.close();
                    }, 1000);
                })
                .fail(function () {
                    alert(i18n.t("message.cannot_print"));
                    vm.waitingPrint = false;
                })
        },

        printDouble(id) {
            this.waitingPrint = true;
            let vm = this;
            var jqxhr = $.get(process.env.VUE_APP_URL + `orders/print-double?id=${id}`, function () { })
                .done(function (res) {
                    vm.waitingPrint = false;
                    const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                    WinPrint.document.write(res);
                    WinPrint.document.close();
                    WinPrint.focus();
                    setTimeout(() => {
                        WinPrint.print();
                        WinPrint.close();
                    }, 1000);
                })
                .fail(function () {
                    alert(i18n.t("message.cannot_print"));
                    vm.waitingPrint = false;
                })
        },
    },
}