<template>
  <div class="top-collectors chart-card">
    <div class="top-collectors__header chart-card__header">
      <h2>{{ title }}</h2>
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ $t(`message.${selected_item}`)
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in dateItems" @click.native="selected_item = item" :key="item">{{
            $t(`message.${item}`) }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="clinics-report">
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>Количество пациентов</h6>
          <span>{{ reports[selected_item].patients_count }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>Общий приход в клинику</h6>
          <span>{{ reports[selected_item].patients_income }}</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>Стационар</h6>
          <span>{{ reports[selected_item].hospital_count }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>Стационар сумма</h6>
          <span>{{ reports[selected_item].hospital_income }} сум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>Дневной стационар</h6>
          <span>{{ reports[selected_item].day_hospital_count }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>Дневной стационар сумма</h6>
          <span>{{ reports[selected_item].day_hospital_income }} сум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>Консультация</h6>
          <span>{{ reports[selected_item].consultation_services_count }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>Консультация сумма</h6>
          <span>{{ reports[selected_item].consultation_services_income }} сум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>Другие услуги</h6>
          <span>{{ reports[selected_item].another_services_count }}</span>
        </el-col>
        <el-col class="pl-2">
          <h6>Другие услуги сумма</h6>
          <span>{{ reports[selected_item].another_services_income }} сум</span>
        </el-col>
      </el-row>
      <el-row class="p-4 d-flex">
        <el-col class="pr-2">
          <h6>Общее</h6>
        </el-col>
        <el-col class="pl-2">
          <span>{{ totalIncome }} сум</span>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
export default {
  props: ["title", "reports"],
  computed: {
    totalIncome() {
      const selectedReport = this.reports[this.selected_item];

      // Суммируем все инкомы
      // Возвращаем сумму
      return (
          selectedReport.patients_income +
          selectedReport.hospital_income +
          selectedReport.day_hospital_income +
          selectedReport.consultation_services_income +
          selectedReport.another_services_income
      );
    }
  },
  data() {
    return {
      selected_item: "daily",
      dateItems: [
        "daily",
        "weekly",
        "monthly",
        "quarterly",
        "half_yearly",
        "yearly",
      ],
    }
  },
  methods: {
    userName(item) {
      if (item.user) return item.user.name + " " + item.user.surname;
      if (item.partner_doctor) return item.partner_doctor.name;
      return "";
    },
    percentage(item) {
      return parseFloat(
        ((item.bonus_amount * 100) / this.doctor.sum_doctor_bonus).toFixed(2)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-card {
  height: 100%;
}

.clinics-report {
  position: relative;

  // &::after {
  //   position: absolute;
  //   content: '';
  //   width: 1px;
  //   height: 100%;
  //   background: #00000018;
  //   left: 47%;
  //   top: 0;
  //   transform: translateX(-50%);
  // }
}

.clinics-report {
  h6 {
    margin: 0;
    color: #222;
  }

  span {
    color: #10b981;
    font-size: 14px;
  }

  .el-row {
    border-bottom: 1px solid #eee;
  }
}
</style>
